import { SUPPORTED_LANGUAGES } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { SupportedLanguageCodeType } from '@/i18n'
import { PageLoader } from '@people/ui/spinners'
import { trpc } from '@utils/trpc'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useIntercom } from 'react-use-intercom'

export const OrgGuard: FC = ({ children }) => {
  const { push, replace, query, asPath, pathname, locale } = useRouter()
  const { boot } = useIntercom()

  const { user } = useAuth()

  const { i18n } = useTranslation()
  const [_, setCookie] = useCookies(['defaultOrg'])
  const { data: userInfo, isLoading } = trpc.useQuery(
    ['user.getUserInfoAndOrgs'],
    {
      enabled: Boolean(user),
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (
          (!data.orgs || data.orgs.length === 0) &&
          !data.isDomainWhitelisted
        ) {
          push('/forbidden')
          return
        }

        if (data.isDomainWhitelisted && data.orgs.length === 0) {
          push('/ask-admin')
          return
        }

        if (!data.user) {
          push('/new-user')
          return
        }
        const defaultOrg = data.orgs.find(o => o.isDefault)
        if (!defaultOrg) {
          if (pathname !== 'setup') push('/setup')
          return
        }
        setCookie('defaultOrg', defaultOrg.slug, { path: '/' })
        if (defaultOrg._count.locations === 0) {
          push(`/${defaultOrg.slug}/setup/location`)
          return
        }
        if (!defaultOrg.website) {
          push(`/${defaultOrg.slug}/setup/contacts`)
          return
        }
        if (pathname === '/') {
          push(`/${defaultOrg.slug}`)
          return
        }
      },
    },
  )
  const currentOrg = userInfo?.orgs.find(org => org.isDefault === true)

  useEffect(() => {
    if (!isLoading && i18n && userInfo && userInfo.user?.defaultLanguage) {
      let localeUserDb: SupportedLanguageCodeType = 'en_US'
      if (userInfo.user?.defaultLanguage) {
        localeUserDb =
          SUPPORTED_LANGUAGES[userInfo.user?.defaultLanguage] || 'en_US'
      }

      const localeBrowserClient = locale
      if (localeUserDb !== localeBrowserClient) {
        replace({ pathname, query }, asPath, { locale: localeUserDb })
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && currentOrg) {
      boot({
        name: user?.displayName || user?.email || '',
        email: user?.email || '',
        userId: user?.uid,
        avatar: {
          imageUrl: userInfo?.user?.image || '',
          type: 'image',
        },
        company: {
          companyId: currentOrg?.id || '',
          name: currentOrg?.name,
          createdAt: currentOrg?.createdAt.toISOString(),
          website: currentOrg?.domain || '',
        },
      })
    }
  }, [userInfo, isLoading])

  return isLoading ? <PageLoader /> : <>{children}</>
}
