import React, { forwardRef } from 'react'

import { classNames } from '../utils/classNames'

export const ButtonVariants = [
  'primary',
  'secondary',
  'disabled',
  'ghost',
  'live',
  'closed',
  'slate',
  'archived',
  'warning',
  'draft',
  'hive',
  'selected',
  'brand',
] as const

export type ButtonVariant = typeof ButtonVariants[number]

export const ButtonSizes = ['sm', 'md', 'lg'] as const
export type ButtonSize = typeof ButtonSizes[number]
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  size?: ButtonSize
  className?: string
  children?: React.ReactNode
}

const variantToStyle = (variant: ButtonVariant) => {
  if (variant === 'primary') {
    return 'bg-blue-600 text-white hover:bg-blue-700 border border-transparent'
  }
  if (variant === 'secondary') {
    return 'border border-slate-300 bg-white text-slate-800 hover:bg-gray-50'
  }
  if (variant === 'ghost') {
    return 'text-slate-800 !shadow-none hover:text-blue-600'
  }
  if (variant === 'disabled') {
    return 'cursor-not-allowed bg-slate-100 text-slate-400'
  }
  if (variant === 'live') {
    return 'border bg-emerald-50 text-emerald-600 border-slate-300'
  }
  if (variant === 'closed') {
    return 'border bg-yellow-50 text-yellow-600 border-slate-300'
  }
  if (variant === 'draft') {
    return 'border bg-slate-50 text-slate-900 border-slate-300'
  }
  if (variant === 'archived') {
    return 'border bg-red-50 text-red-600 border-slate-300'
  }
  if (variant === 'warning') {
    return 'bg-red-600 text-white hover:bg-red-700'
  }
  if (variant === 'hive') {
    return 'bg-[#323232] text-white hover:bg-[#2b2b2b]'
  }
  if (variant === 'selected') {
    return 'bg-blue-50 !text-blue-600 border-blue-600 border'
  }
  if (variant === 'brand') {
    return 'bg-brand text-white hover:saturate-[.8]'
  }
  return ''
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, children, size = 'lg', ...other }, ref) => {
    return (
      <button
        disabled={variant === 'disabled'}
        ref={ref}
        className={classNames(
          variantToStyle(variant),
          'inline-flex items-center justify-center rounded-md font-medium text-slate-900 whitespace-nowrap shadow-sm disabled:bg-slate-100 disabled:text-slate-400',
          size === 'lg' ? 'py-2 max-h-10 px-2.5 gap-1 text-base' : '',
          size === 'md' ? 'py-2 px-2 h-8 gap-1 text-sm' : '',
          size === 'sm' ? 'py-2 px-2 h-[26px] gap-1 text-sm' : '',
          className || '',
        )}
        {...other}
      >
        {children}
      </button>
    )
  },
)

export default Button
