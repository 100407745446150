import { useContext } from 'react'

import { NotificationContext } from '../contexts/notification'

export const useNotification = () => {
  const notifications = useContext(NotificationContext)

  if (!notifications) {
    throw new Error('useNotification must be used within NotificationProvider')
  }
  return notifications
}
