import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { FC, Fragment } from 'react'

import { classNames } from '../utils'

export type NotificationType = 'success' | 'error' | 'info'

export interface NotificationProps {
  className?: string
  show: boolean
  type: NotificationType
  title: string
  message?: string | (() => JSX.Element)
  setShow: (show: boolean) => void
  customIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const Notification: FC<NotificationProps> = ({
  className,
  show,
  title,
  type,
  message,
  setShow,
  customIcon,
}) => {
  const Icon = customIcon
  return (
    <>
      <div
        aria-live="assertive"
        className={classNames(
          className ?? '',
          'pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[9999]',
        )}
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="slider-ignore-outside-click pointer-events-auto w-full max-w-sm overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-slate-200">
              <div className="p-4">
                <div
                  className={classNames(
                    'slider-ignore-outside-click flex',
                    message ? 'items-start' : 'items-center',
                  )}
                >
                  <div className="slider-ignore-outside-click flex-shrink-0">
                    <div
                      className={
                        type === 'success'
                          ? 'text-emerald-500'
                          : type === 'error'
                          ? 'text-red-500'
                          : 'text-blue-600'
                      }
                    >
                      {!customIcon && type === 'success' && (
                        <CheckCircleIcon
                          className="slider-ignore-outside-click h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {!customIcon && type === 'error' && (
                        <XCircleIcon
                          className="slider-ignore-outside-click h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {!customIcon && type === 'info' && (
                        <InformationCircleIcon
                          className="slider-ignore-outside-click h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {Icon && (
                        <Icon className="slider-ignore-outside-click h-5 w-5" />
                      )}
                    </div>
                  </div>
                  <div className="slider-ignore-outside-click ml-3 -mt-0.5 w-0 flex-1">
                    <p className="slider-ignore-outside-click text-sm font-semibold text-slate-900">
                      {title}
                    </p>
                    {message &&
                      (typeof message === 'function' ? (
                        message()
                      ) : (
                        <p className="slider-ignore-outside-click mt-1 text-sm text-slate-600">
                          {message}
                        </p>
                      ))}
                  </div>
                  <div className="slider-ignore-outside-click ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="slider-ignore-outside-click inline-flex rounded-md bg-white text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="slider-ignore-outside-click sr-only">
                        Close
                      </span>
                      <XIcon
                        className="slider-ignore-outside-click h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

export default Notification
