import * as Dialog from '@radix-ui/react-dialog'
import { FC } from 'react'

import { classNames } from '../utils'

export interface ModalShellProps {
  className?: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onInteractOutside?: (e: any) => void
}

export const ModalShell: FC<ModalShellProps> = ({
  className,
  isOpen,
  setIsOpen,
  children,
  onInteractOutside,
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={open => setIsOpen(open)}>
      <Dialog.Portal>
        <Dialog.Overlay className="inset-0 z-30 fixed bg-slate-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        <Dialog.Content asChild {...{ onInteractOutside }}>
          <div className="fixed z-30 inset-0 overflow-y-auto mt-[100px] w-fit mx-auto">
            <div className="flex h-fit items-start justify-center  text-center">
              <div
                className={classNames(
                  className ?? '',
                  'relative transform overflow-hidden rounded-md bg-white shadow-lg transition-all',
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ModalShell
