import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@heroicons/react/outline'
import Fuse from 'fuse.js'
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { Button } from '../buttons'
import { classNames } from '../utils'
import { FormStyles } from './FormStyles'

export interface DropdownItemProps {
  name: string
  value: string | number | null
  rightText?: string
  icon?: ReactNode
  noCheck?: boolean
  disabled?: boolean
}

export interface FormDropdownProps {
  placeholder?: ReactNode
  label?: string
  disabled?: boolean
  id: string
  className?: string
  items: DropdownItemProps[]
  buttonClassName?: string
  optionsContainerClassName?: string
  onChange: (newValue?: DropdownItemProps) => void
  footer?: JSX.Element
  value?: DropdownItemProps
  position?: 'bottom-start' | 'top-start'
  searchDisabled?: boolean
  error?: string
}

export const FormDropdown: FC<FormDropdownProps> = props => {
  const [query, setQuery] = useState('')
  const dropdownInputRef = useRef<HTMLInputElement | null>(null)
  const resultsListRef = useRef<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement>()
  const [popperElement, setPopperElement] = useState<HTMLElement>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.position || 'bottom-start',
  })

  const fuse = new Fuse(props.items, {
    includeScore: true,
    keys: ['name'],
    isCaseSensitive: false,
  })

  const filteredResults =
    query === ''
      ? props.items
      : fuse.search(query).map(res => ({ ...res.item }))

  useEffect(() => {
    if (dropdownInputRef.current) {
      dropdownInputRef.current.focus()
    }
  }, [popperElement])

  useEffect(() => {
    // when query changes we scroll to the first item
    if (resultsListRef.current) {
      resultsListRef.current.scrollTop = 0
    }
  }, [query])

  return (
    <div className={props.className}>
      <>
        {props.label && (
          <label
            htmlFor={props.id}
            className={classNames(
              'block text-sm font-medium text-slate-700 mb-1',
              props.error ? 'text-red-500' : '',
            )}
          >
            {props.label}
          </label>
        )}
        <Listbox
          defaultValue={'Select'}
          disabled={props.disabled}
          value={props.value?.value}
          onChange={newValue => {
            props.onChange(props.items.find(item => item.value === newValue))
          }}
          /* @ts-ignore */
          ref={setReferenceElement}
        >
          <div className="relative">
            <Listbox.Button
              as={Button}
              variant="secondary"
              className={classNames(
                props.buttonClassName ?? '',
                FormStyles({
                  disabled: props.disabled,
                  hasError: Boolean(props.error),
                }),
                '!shadow-none !rounded-md ',
                'placeholder-black',
                props.error
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                  : '',
              )}
            >
              <span className="block truncate">
                {!props.value ? (
                  <span className="text-slate-400">{props.placeholder}</span>
                ) : (
                  <div className="flex items-center">
                    {props.value.icon && (
                      <div className="mr-2 flex items-center">
                        {props.value.icon}
                      </div>
                    )}
                    {props.value.name}
                  </div>
                )}
              </span>
              <span className="pointer-events-none flex flex-1 items-center justify-end pl-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-blue-600"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                placeholder="Select an option"
                className={classNames(
                  'absolute z-10 mt-2 w-full min-w-fit overflow-auto bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-t-md',
                  props.optionsContainerClassName
                    ? props.optionsContainerClassName
                    : '',
                  !!props.disabled
                    ? 'rounded-md mt-1'
                    : 'rounded-t-0 rounded-b-md mt-0',
                )}
                /* @ts-ignore */
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {!props.searchDisabled && (
                  <div className="flex items-center border-b rounded-t-md shadow-none">
                    <div className="px-3">
                      <SearchIcon className="text-slate-500" width={20} />
                    </div>
                    <input
                      value={query}
                      ref={dropdownInputRef}
                      onChange={e => setQuery(e.target.value)}
                      type="text"
                      className="w-full border-none outline-none shadow-none focus-styles px-0 pl-0.5 rounded-tr-md"
                    />
                  </div>
                )}
                <div
                  ref={resultsListRef}
                  className="overflow-auto max-h-[250px]"
                >
                  {filteredResults.map(item => (
                    <Listbox.Option
                      key={item.value}
                      disabled={Boolean(item.disabled)}
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'bg-slate-100 text-blue-600'
                            : 'text-slate-900',
                          item.disabled ? 'opacity-50' : '',
                          item.noCheck ? 'pl-4' : 'pl-10',
                          'relative cursor-default select-none py-2 pr-4',
                        )
                      }
                      value={item.value}
                    >
                      {({ selected }) => (
                        <div className="flex">
                          {item.icon && (
                            <div className="mr-2 justify-center flex items-center">
                              {item.icon}
                            </div>
                          )}
                          <span
                            className={`block truncate ${
                              selected ? 'font-semibold' : 'font-medium'
                            }`}
                          >
                            {item.name}
                          </span>
                          {selected && !item.noCheck ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>

                {props.footer ? props.footer : null}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {props.error && (
          <span className="mt-1 text-red-500 text-sm">{props.error}</span>
        )}
      </>
    </div>
  )
}

export default FormDropdown
