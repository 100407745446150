import { useDocumentVisibility } from '@mantine/hooks'
import { auth } from '@utils/firebase'
import type { Auth, User } from 'firebase/auth'
import { signOut as firebaseSignOut } from 'firebase/auth'
import type { FC } from 'react'
import { createContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

export const AuthContext = createContext<
  | {
      auth: Auth
      loading: boolean
      user: User | null | undefined
      error: Error | undefined
      signOut: () => void
      justSignedOut: boolean
      setJustSignedOut: (value: boolean) => void
    }
  | undefined
>(undefined)

AuthContext.displayName = 'AuthContext'

export const AuthProvider: FC = props => {
  const documentState = useDocumentVisibility()

  const { children } = props
  const [user, loading, error] = useAuthState(auth)
  const [justSignedOut, setJustSignedOut] = useState(false)

  const signOut = () => {
    setJustSignedOut(true)
    firebaseSignOut(auth)
  }

  async function checkSession() {
    if (documentState === 'visible') {
      try {
        await auth.currentUser?.reload()
      } catch (_) {}
    }
  }

  useEffect(() => {
    checkSession()
  }, [documentState])

  const value = {
    user,
    error,
    auth,
    loading,
    signOut,
    justSignedOut,
    setJustSignedOut,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
