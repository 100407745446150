import { useAuth } from '@/hooks/useAuth'
import { isDemoUser } from '@/utils/isDemoUser'
import ls from '@livesession/sdk'
import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

export const AuthGuard: FC = props => {
  const { children } = props
  const { user, loading, justSignedOut, setJustSignedOut } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (!loading) {
      if (!user || user.isAnonymous) {
        if (justSignedOut) {
          setJustSignedOut(false)
          router.push('/signin')
        } else {
          router.push(`/signin?redirect=${router.asPath}`)
        }
      } else if (
        !user.emailVerified &&
        user.providerData[0].providerId !== 'microsoft.com' &&
        !isDemoUser(user.email) &&
        router.pathname !== '/unverified-email' &&
        router.pathname !== '/verify-email'
      ) {
        console.log('user unverified data: ', user)
        router.push('/unverified-email')
      }
    }
  }, [loading, router, user])

  useEffect(() => {
    if (user && !user.isAnonymous && user.email) {
      Sentry.setUser({
        email: user.email,
        username: user.displayName || undefined,
        ip_address: '{{auto}}',
      })
      if (ls && process.env.NODE_ENV !== 'development') {
        try {
          ls.identify({
            email: user.email,
          })
        } catch (e) {}
      }
      
    }
  }, [user?.email])

  if (user && !user.isAnonymous) {
    return <>{children}</>
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null
}
