import type { FC, ReactNode } from 'react'

import { classNames } from '../utils/classNames'

type CardHeaderVariant = 'default' | 'slim'
export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  header?: ReactNode
  cardHeaderVariant?: CardHeaderVariant
}

export const Card: FC<CardProps> = ({
  className,
  header,
  cardHeaderVariant = 'default',
  children,
  ...other
}) => {
  return (
    <div
      className={classNames(className ?? '', 'bg-white shadow rounded-md')}
      {...other}
    >
      {header && (
        <div
          className={classNames(
            cardHeaderVariant === 'slim' ? 'py-3 px-4' : 'py-4 px-5',
            'flex  text-slate-900 text-sm font-semibold border-b border-slate-200',
          )}
        >
          {header}
        </div>
      )}
      {children}
    </div>
  )
}

export default Card
