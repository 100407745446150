var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

import { string2slug } from '@people/common/utils/string2slug'

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'development'
      ? undefined
      : process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1,
  environment: string2slug(
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
      ? process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || ''
      : process.env.NEXT_PUBLIC_VERCEL_ENV || '',
  ),
  attachStacktrace: true,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
    new ExtraErrorDataIntegration({
      depth: 3,
    }),
    new BrowserTracing({
      tracePropagationTargets: ['flash.teero.com'],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
