const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

const isBrowser = typeof window !== 'undefined'

module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: false,
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: 'en_US',
    locales: ['en_US', 'de_DE', 'fr_FR', 'it_IT', 'pl_PL', 'nl_NL', 'es_ES'],
  },
  backend: {
    backendOptions: [
      {
        expirationTime: 60 * 60 * 1000, // 1 hour
      },
    ],
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  localExtension: null,
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  ns: ['common'],
  keySeparator: '.',
  react: { useSuspense: false },
  saveMissing: true,
  onPreInitI18next: i18n => {
    i18n.on('missingKey', (lngs, namespace, key, res) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Missing key: ${lngs} ${namespace} ${key} ${res}`)
      }
      if (!isBrowser) return
      localStorage.removeItem(`i18next_res_${lngs}-common`)
    })
  },
}
