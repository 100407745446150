import { XIcon } from '@heroicons/react/outline'
import type { FC } from 'react'

import { ButtonSpinner } from '../spinners'
import { classNames } from '../utils'
import ModalShell from './ModalShell'

export interface HeaderProps {
  className?: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  header: string | (() => JSX.Element)
  isLoading?: boolean
  onInteractOutside?: (e: FocusEvent | PointerEvent) => void
}

export const HeaderModal: FC<HeaderProps> = ({
  className,
  isOpen,
  setIsOpen,
  header,
  children,
  isLoading,
  onInteractOutside,
}) => {
  return (
    <ModalShell
      className={classNames(
        'w-full sm:w-auto sm:min-w-[460px]',
        className ?? '',
      )}
      {...{ isOpen, setIsOpen, onInteractOutside }}
    >
      {isLoading ? (
        <div className="p-32 items-center flex justify-center">
          <ButtonSpinner className=" h-8 w-8 animate-spin" />
        </div>
      ) : (
        <>
          <div className="border-b border-slate-200 px-5 py-3">
            <div className="flex items-center">
              {typeof header === 'function' ? (
                header()
              ) : (
                <h3 className="font-semibold text-slate-900">{header}</h3>
              )}
              <button
                type="button"
                className={classNames('ml-auto', 'self-start')}
                onClick={() => setIsOpen(false)}
              >
                <XIcon className="h-5 w-5 text-slate-400 hover:text-blue-600" />
              </button>
            </div>
          </div>
          {children}
        </>
      )}
    </ModalShell>
  )
}

export default HeaderModal
