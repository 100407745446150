import { Switch } from '@headlessui/react'
import type { FC } from 'react'

import { classNames } from '../utils/classNames'

interface i18nProps {
  useSetting: string
}

export interface SimpleToggleProps {
  className?: string
  enabledColor?: string
  disabledColor?: string
  defaultEnabled?: boolean
  isToggleFrozen?: boolean
  frozenToggleColor?: string

  parentToggleChangeHandler?: (isEnabled: boolean) => void

  i18n?: i18nProps
}

export const SimpleToggle: FC<SimpleToggleProps> = ({
  className,
  enabledColor = 'bg-teal-500',
  disabledColor = 'bg-slate-400',
  defaultEnabled = false,
  isToggleFrozen = false,
  frozenToggleColor = 'bg-slate-300 cursor-auto',
  parentToggleChangeHandler,
  i18n,
  ...other
}) => {
  const handleToggleChange = () => {
    if (isToggleFrozen) {
      return
    }

    if (parentToggleChangeHandler) {
      parentToggleChangeHandler(!defaultEnabled)
    }
  }

  return (
    <Switch
      as="div"
      checked={defaultEnabled}
      onChange={handleToggleChange}
      className={classNames(
        className ?? '',
        isToggleFrozen
          ? frozenToggleColor
          : defaultEnabled
          ? enabledColor
          : disabledColor,
        'h-4.5 relative inline-flex w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
      )}
      {...other}
    >
      <span className="sr-only">{i18n?.useSetting || 'Use setting'}</span>
      <span
        aria-hidden="true"
        className={classNames(
          defaultEnabled ? 'translate-x-[18px]' : 'translate-x-0',
          'pointer-events-none inline-block h-3.5 w-3.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  )
}

export default SimpleToggle
