import { isLocalEnvironment } from '@/utils/isLocalEnvironment'

export const CANDIDATE_APP_URL = isLocalEnvironment
  ? 'http://localhost:3002'
  : process.env.NEXT_PUBLIC_CANDIDATE_APP_URL || 'https://www.teero.com'

export const NEXT_PUBLIC_TINYMCE_API_KEY =
  process.env.NEXT_PUBLIC_TINYMCE_API_KEY || ''

export const NEXT_PUBLIC_RECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''

export const NEXT_PUBLIC_API_SERVER_URL =
  process.env.NEXT_PUBLIC_API_SERVER_URL || ''

export const NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY = '9fcb7e26605c55068e16'
