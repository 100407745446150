import type { FC } from 'react'
import React, { createContext, useState } from 'react'

import { Notification } from '../notification'
import { NotificationType } from '../notification/Notification'

type showNotifcationType = ({
  title,
  message,
  type,
  timeout,
  customIcon,
}: {
  title: string
  message?: string | (() => JSX.Element)
  type: NotificationType
  timeout?: number | null
  customIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}) => void

export const NotificationContext = createContext<
  | {
      showNotification: showNotifcationType
    }
  | undefined
>(undefined)

NotificationContext.displayName = 'NotificationContext'

export interface NotificationProviderProps {
  className?: string
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  className,
  children,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [message, setMessage] = useState<string | (() => JSX.Element)>('')
  const [type, setType] = useState<NotificationType>('success')
  const [customIcon, setCustomIcon] =
    useState<(props: React.SVGProps<SVGSVGElement>) => JSX.Element>()

  let timer: NodeJS.Timeout

  const showNotification: showNotifcationType = ({
    title,
    message,
    type,
    timeout = 3000,
    customIcon,
  }) => {
    clearTimeout(timer)
    setTitle(title)
    setMessage(message ?? '')
    setType(type)
    setCustomIcon(customIcon)
    setShow(true)
    if (timeout) {
      timer = setTimeout(() => {
        setShow(false)
      }, timeout)
    }
  }

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Notification
        className={className}
        {...{ show, setShow, title, message, type, customIcon }}
      />
      {children}
    </NotificationContext.Provider>
  )
}
