import { useAuth } from '@/hooks/useAuth'
import { Button } from '@people/ui/buttons'
import { Card } from '@people/ui/cards'
import { PageLoader } from '@people/ui/spinners'

import { trpc } from '@utils/trpc'
import { useRouter } from 'next/router'
import { FC } from 'react'

export const CalendarGuard: FC = ({ children }) => {
  const { push, replace, pathname } = useRouter()

  const { user } = useAuth()

  const { data: calendars, isLoading: isLoadingCalendars } = trpc.useQuery(
    ['user.calendar.list'],
    {
      retry: true,
      enabled: !!user,
    },
  )

  const isNylasAccountInvalid =
    calendars &&
    (calendars?.nylas.length === 0 ||
      (calendars?.nylas.length > 0 &&
        calendars?.nylas[0].syncState === 'invalid'))

  return isLoadingCalendars ? (
    <PageLoader />
  ) : (
    <>
      {isNylasAccountInvalid && pathname !== '/settings/calendar' && (
        <div className="flex justify-center w-full">
          <Card className="flex flex-col p-4 fixed bottom-0 mx-auto font-semibold gap-4 !bg-red-100 z-50">
            <span>
              {calendars?.nylas.length === 0 ? 'Connect' : 'Reconnect'} your
              calendar to continue using Interviews.{' '}
            </span>
            <Button
              variant="primary"
              onClick={() => push('/settings/calendar')}
            >
              Go to Settings
            </Button>
          </Card>
        </div>
      )}
      {children}
    </>
  )
}
