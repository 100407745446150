import IframeModal from '@/components/iframe/IframeModal'
import { NEXT_PUBLIC_API_SERVER_URL } from '@/config/environment'
import { AuthProvider } from '@/contexts/auth'
import { AuthGuard, CalendarGuard, OrgGuard } from '@/guards'
import { isLocalEnvironment } from '@/utils/isLocalEnvironment'
import ls from '@livesession/sdk'
import { httpBatchLink } from '@trpc/client/links/httpBatchLink'
import { httpLink } from '@trpc/client/links/httpLink'
import { loggerLink } from '@trpc/client/links/loggerLink'
import { splitLink } from '@trpc/client/links/splitLink'
import { withTRPC } from '@trpc/next'
import { auth } from '@utils/firebase'
import { NextPage } from 'next'
import { appWithTranslation } from 'next-i18next'
import nextI18nextConfig from 'next-i18next.config'
import type { AppProps } from 'next/app'
import { AppType } from 'next/dist/shared/lib/utils'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import { useEffect } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import 'src/styles/globals.css'
import superjson from 'superjson'

import { AppRouter } from '@people/api/routers/_app'
import { NotificationProvider } from '@people/ui/contexts/notification'

const INTERCOM_APP_ID = 'rddpqguk'

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean
  requireOrg?: boolean
}

const MyApp = appWithTranslation((props: AppProps) => {
  const {
    Component,
    pageProps: { session, ...pageProps },
  }: { Component: NextApplicationPage; pageProps: any } = props

  /** It was an annoying type issue in Typescript. So I ended up following this answer on stackoverflow
  https://stackoverflow.com/a/71869550 */
  // const Comp = Component as any

  useEffect(() => {
    ls.init('8a717e82.7d9a8c81', { keystrokes: false })
    ls.newPageView()
  }, [])

  return (
    <>
      <Head>
        <title>Build your workforce faster with Teero - Teero</title>
        <meta name="google" content="notranslate"></meta>
      </Head>
      {/* if requireAuth property is present - protect the page */}
      <NextNProgress
        color="#2563eb"
        height={2}
        options={{ showSpinner: false }}
      />
      <NotificationProvider className="z-50 mt-12">
        <AuthProvider>
          {Component.requireAuth === undefined || Component.requireAuth ? (
            <AuthGuard>
              <IntercomProvider appId={INTERCOM_APP_ID}>
                {Component.requireOrg === undefined || Component.requireOrg ? (
                  <OrgGuard>
                    <CalendarGuard>
                      <IframeModal />
                      <Component {...pageProps} />
                    </CalendarGuard>
                  </OrgGuard>
                ) : (
                  <Component {...pageProps} />
                )}
              </IntercomProvider>
            </AuthGuard>
          ) : (
            // public page
            <Component {...pageProps} />
          )}
        </AuthProvider>
      </NotificationProvider>
    </>
  )
}, nextI18nextConfig) as AppType

export default withTRPC<AppRouter>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,

      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: opts =>
            isLocalEnvironment ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context.skipBatch === true
          },
          true: httpLink({
            url: `${NEXT_PUBLIC_API_SERVER_URL}/trpc`,
          }),
          false: httpBatchLink({
            url: `${NEXT_PUBLIC_API_SERVER_URL}/trpc`,
          }),
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: {
          queries: {
            onError: (error: unknown) => {
              console.error(
                `You can't get this data. Something went wrong: ${
                  (error as any).message
                }`,
              )
            },
          },
          mutations: {
            onError: (error: unknown) => {
              console.error(
                `You can't do this action. Something went wrong: ${
                  (error as any).message
                }`,
              )
            },
          },
        },
      },
      async headers() {
        const accessToken = await auth.currentUser?.getIdToken()
        return {
          Authorization: `Bearer ${accessToken}`,
        }
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
  // /**
  //  * Set headers or status code when doing SSR
  //  */
  // responseMeta({ clientErrors }) {
  //   if (clientErrors.length) {
  //     // propagate http first error from API calls
  //     return {
  //       status: clientErrors[0].data?.httpStatus ?? 500,
  //     }
  //   }

  //   // for app caching with SSR see https://trpc.io/docs/caching
  //   // cache full page for 1 day + revalidate once every second
  //   const ONE_DAY_IN_SECONDS = 60 * 60 * 24
  //   return {
  //     'Cache-Control': `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
  //   }
  // },
})(MyApp)
