import { getApps, initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}
const NEXT_PUBLIC_FIREBASE_PUBLIC_STORAGE_BUCKET = process.env
  .NEXT_PUBLIC_FIREBASE_PUBLIC_STORAGE_BUCKET as string

if (!getApps.length) {
  initializeApp(firebaseConfig)
}

const app = getApps()[0]

export const auth = getAuth()
export const googleAuthProvider = new GoogleAuthProvider()

export const storage = getStorage(
  app,
  `gs://${process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET}`,
)

export const publicStorage = getStorage(
  app,
  `gs://${NEXT_PUBLIC_FIREBASE_PUBLIC_STORAGE_BUCKET}`,
)

export const getBucketStorage = (bucket: string) => {
  if (bucket === process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET) {
    return storage
  }
  if (bucket === NEXT_PUBLIC_FIREBASE_PUBLIC_STORAGE_BUCKET) {
    return publicStorage
  }
  return getStorage(app, `gs://${bucket}`)
}
