import { CANDIDATE_APP_URL } from '@/config/environment'
import { DuplicateIcon } from '@heroicons/react/outline'
import type { SupportedLanguageCode } from '@people/common/shared-types/usher/supportedLanguageCodes'
import { Button } from '@people/ui/buttons'
import { FormDropdown } from '@people/ui/form'
import { useNotification } from '@people/ui/hooks/useNotification'
import { HeaderModal } from '@people/ui/modals'
import { SimpleToggle } from '@people/ui/toggles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useReducer } from 'react'

export const useIframeModal = () => {
  const { query, replace } = useRouter()

  const iFrameModalOpen = ({
    jobFullSlug,
    positionSlug,
    shareJobFullSlug,
    locationFullSlug,
    orgSlug,
    isTalentPool,
  }: {
    jobFullSlug?: string
    positionSlug?: string | null
    shareJobFullSlug?: string
    locationFullSlug?: string
    orgSlug?: string
    isTalentPool?: boolean
  }) => {
    const iframeProp = jobFullSlug
      ? {
          iframeJob: jobFullSlug,
          iframePosition: positionSlug,
        }
      : locationFullSlug
      ? {
          iframeLocation: locationFullSlug,
        }
      : orgSlug
      ? {
          iframeOrg: orgSlug,
        }
      : shareJobFullSlug
      ? {
          iframeShareJob: shareJobFullSlug,
          iframePosition: positionSlug,
        }
      : {}

    replace({
      query: {
        ...query,
        iframe: 'true',
        ...iframeProp,
        ...(isTalentPool ? { isTalentPool: 'true' } : {}),
      },
    })
  }

  return { iFrameModalOpen }
}

type IframeModalAction =
  | {
      type: 'hasLanguageSelector'
      payload?: boolean
    }
  | {
      type: 'hasJobDescription'
      payload?: boolean
    }
  | {
      type: 'hasLocationPicker'
      payload?: boolean
    }
  | {
      type: 'hasCompanyDescription'
      payload?: boolean
    }
  | {
      type: 'setDefaultLang'
      payload: SupportedLanguageCode
    }
  | {
      type: 'reset'
      payload: IframeModalState
    }

interface IframeModalState {
  defaultLang: SupportedLanguageCode | null
  hasLanguageSelector: boolean
  hasJobDescription: boolean
  hasCompanyDescription: boolean
  hasLocationPicker: boolean
}

const iframeModalReducer = (
  state: IframeModalState,
  action: IframeModalAction,
): IframeModalState => {
  const { type, payload } = action
  switch (type) {
    case 'hasLanguageSelector':
      return {
        ...state,
        hasLanguageSelector: !state.hasLanguageSelector,
      }
    case 'hasJobDescription':
      return {
        ...state,
        hasJobDescription: !state.hasJobDescription,
      }
    case 'hasCompanyDescription':
      return {
        ...state,
        hasCompanyDescription: !state.hasCompanyDescription,
      }
    case 'hasLocationPicker':
      return {
        ...state,
        hasLocationPicker: !state.hasLocationPicker,
      }
    case 'setDefaultLang':
      return {
        ...state,
        defaultLang: payload,
      }
    case 'reset':
      return payload
  }
}

const getUrl = (
  state: IframeModalState,
  isEmbedPath: boolean,
  embedUrl: string,
) => {
  const {
    defaultLang,
    hasLanguageSelector,
    hasJobDescription,
    hasCompanyDescription,
  } = state
  const url = new URL(
    `${CANDIDATE_APP_URL}/${isEmbedPath ? 'embed' : 'jobs'}/${embedUrl}`,
  )
  if (defaultLang !== null) {
    url.searchParams.append('lng', defaultLang)
  }
  if (!hasLanguageSelector) {
    url.searchParams.append('noLang', 'true')
  }
  if (hasJobDescription) {
    url.searchParams.append('hasDesc', 'true')
  }
  if (hasCompanyDescription) {
    url.searchParams.append('hasCompDesc', 'true')
  }
  return url.toString()
}

const initState = ({
  isJobShare,
  isTalentPool,
}: {
  isJobShare: boolean
  isTalentPool: boolean
}) => ({
  defaultLang: null,
  hasLanguageSelector: true,
  hasJobDescription: isTalentPool ? false : isJobShare,
  hasCompanyDescription: isTalentPool ? false : isJobShare,
  hasLocationPicker: false,
})

export const IframeModal: FC = () => {
  const { t } = useTranslation('common')
  const { query, replace } = useRouter()
  const { showNotification } = useNotification()
  const {
    iframe,
    iframeJob,
    iframeLocation,
    iframeShareJob,
    iframeOrg,
    iframePosition,
    isTalentPool,
    ...restQuery
  } = query

  const [state, dispatch] = useReducer(
    iframeModalReducer,
    initState({
      isJobShare: Boolean(iframeShareJob),
      isTalentPool: Boolean(isTalentPool),
    }),
  )
  const isOpen = iframe === 'true'
  const setIsOpen = () => {
    replace({ query: { ...restQuery } })
  }

  useEffect(() => {
    dispatch({
      type: 'reset',
      payload: initState({
        isJobShare: Boolean(iframeShareJob),
        isTalentPool: Boolean(isTalentPool),
      }),
    })
  }, [isOpen])

  let embedWhat: string
  if (iframeJob) {
    embedWhat = t('embedJobForm')
  }
  if (iframeLocation) {
    embedWhat = t('embedLocation')
  }
  if (iframeShareJob) {
    embedWhat = t('shareJobForm')
  }
  if (iframeOrg) {
    embedWhat = t('embedCareer')
  }

  const languageMap = new Map<SupportedLanguageCode | null, string>([
    [null, t('userDefaultLanugaue')],
    ['en-US', t('english')],
    ['fr-FR', t('french')],
    ['es-ES', t('spanish')],
    ['de-DE', t('german')],
    ['it-IT', t('italian')],
    ['pl-PL', t('polish')],
    ['nl-NL', t('dutch')],
    ['da-DK', t('danish')],
  ])

  const actionsMap = new Map<string, string>([
    ['hasLanguageSelector', t('languageSelector')],
    ['hasJobDescription', t('jobDescription')],
    ['hasCompanyDescription', t('companyDescription')],
    ['hasLocationPicker', t('locationSelector')],
  ])

  let embedUrl: string = iframeLocation as string
  if (iframeJob || iframeShareJob) {
    embedUrl = state.hasLocationPicker
      ? `${query.orgSlug}/all/${iframePosition}`
      : `${iframeJob || iframeShareJob}/apply`
  }

  const url = getUrl(state, Boolean(iframeJob), embedUrl)

  const iFrameCode = `<iframe src="${url}" width="100%" height="100%"></iframe>`

  return (
    <>
      <HeaderModal
        header={() => (
          <div className="flex flex-col gap-1 items-start text-slate-900 font-semibold">
            <span>{embedWhat}</span>
          </div>
        )}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="lg:w-[1000px] min-h-[400px] lg:min-h-[600px]"
      >
        <div className="flex flex-col-reverse lg:flex-row ">
          <div className="flex flex-col lg:w-[65%]">
            <iframe
              src={getUrl(state, Boolean(iframeJob), embedUrl)}
              width="100%"
              className="border border-slate-200 lg:border-slate-50 min-h-[400px] lg:min-h-[900px]"
            />
          </div>
          <div className="flex flex-col lg:w-[35%] justify-between border-t lg:border-t-0 lg:border-l border-slate-200">
            <div className="text-left p-5 gap-4 flex flex-col border-b border-b-slate-200 flex-1">
              <div className="space-y-4 border-b border-slate-200 pb-4">
                <FormDropdown
                  id="language"
                  label={t('defaultPageLanguage')}
                  buttonClassName="!shadow-none"
                  value={{
                    name:
                      state.defaultLang === null
                        ? t('userDefaultLanugaue')
                        : languageMap.get(state.defaultLang) || '',
                    value: state.defaultLang,
                  }}
                  items={Array.from(languageMap.entries()).map(
                    ([key, value]) => ({
                      name: value,
                      value: key,
                    }),
                  )}
                  onChange={value => {
                    dispatch({
                      type: 'setDefaultLang',
                      payload: value?.value as SupportedLanguageCode,
                    })
                  }}
                />
                {([
                  'hasJobDescription',
                  'hasCompanyDescription',
                  'hasLanguageSelector',
                  'hasLocationPicker',
                ] as IframeModalAction['type'][])
                  .filter(a =>
                    Boolean(isTalentPool) ? a !== 'hasLocationPicker' : true,
                  )
                  .filter(a =>
                    iframeJob || iframeShareJob
                      ? true
                      : a === 'hasLanguageSelector',
                  )
                  .map(action => (
                    <button
                      key={action}
                      className="flex flex-row gap-2.5 px-2.5 py-2 border border-slate-300 rounded-md justify-between items-center w-full"
                      onClick={() => {
                        if (
                          action !== 'setDefaultLang' &&
                          action !== 'reset'
                        ) {
                          dispatch({
                            type: action,
                          })
                        }
                      }}
                    >
                      <span className="text-slate-900 font-medium">
                        {actionsMap.get(action)}
                      </span>
                      <SimpleToggle
                        defaultEnabled={
                          state[action as keyof IframeModalState] as boolean
                        }
                        className="ml-auto"
                      />
                    </button>
                  ))}
              </div>
              {iframeShareJob ? (
                <>
                  <div className="border-slate-200 border rounded-md p-3 text-md break-all text-left">
                    <a
                      href={url}
                      className="text-blue-600 underline"
                      about="_blank"
                    >
                      {url}
                    </a>
                  </div>
                  <div className="flex-1 text-left">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(url)
                        showNotification({
                          title: t('urlCopied'),
                          message: `${embedWhat} ${t('urlCopiedMessage')}`,
                          type: 'success',
                        })
                      }}
                    >
                      <DuplicateIcon className="h-[18px] w-[18px] text-blue-600 mr-2" />
                      {t('copyURL')}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <code className="bg-slate-50 border-slate-200 border rounded-md p-3 text-md text-slate-600 break-all text-left">
                    {iFrameCode}
                  </code>
                  <div className="flex-1 text-left">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(iFrameCode)
                        showNotification({
                          title: t('iframeCodeCopied'),
                          message: `${embedWhat} ${t(
                            'iframeCodeCopiedMessage',
                          )}`,
                          type: 'success',
                        })
                      }}
                    >
                      <DuplicateIcon className="h-[18px] w-[18px] text-blue-600 mr-2" />
                      {t('copyCode')}
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div></div>
          </div>
        </div>
      </HeaderModal>
    </>
  )
}

export default IframeModal
