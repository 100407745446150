import { SupportedLanguageCodeType } from '@/i18n/_types'
import { LanguageType } from '@prisma/client'

const GERMAN_LANGUAGE_CODE: SupportedLanguageCodeType = 'de_DE'
const FRENCH_LANGUAGE_CODE: SupportedLanguageCodeType = 'fr_FR'
const ITALIAN_LANGUAGE_CODE: SupportedLanguageCodeType = 'it_IT'
const US_ENGLISH_LANGUAGE_CODE: SupportedLanguageCodeType = 'en_US'
const SPANISH_LANGUAGE_CODE: SupportedLanguageCodeType = 'es_ES'
const DUTCH_LANGUAGE_CODE: SupportedLanguageCodeType = 'nl_NL'
const POLISH_LANGUAGE_CODE: SupportedLanguageCodeType = 'pl_PL'

export const SUPPORTED_LANGUAGES: Record<
  LanguageType,
  SupportedLanguageCodeType
> = {
  ENGLISH: US_ENGLISH_LANGUAGE_CODE,
  SPANISH: SPANISH_LANGUAGE_CODE,
  GERMAN: GERMAN_LANGUAGE_CODE,
  FRENCH: FRENCH_LANGUAGE_CODE,
  ITALIAN: ITALIAN_LANGUAGE_CODE,
  POLISH: POLISH_LANGUAGE_CODE,
  DUTCH: DUTCH_LANGUAGE_CODE,
}
